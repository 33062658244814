<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div>
        <h2>フィルター</h2>
        <a-form layout="inline">
          <a-form-item label="ユーザー名">
            <a-auto-complete
              :data-source="autocompleteData"
              style="width: 200px"
              placeholder=" ユーザー名"
              :filter-option="filterOption"
              @select="searchAction"
              @search="searchAction"
            />
          </a-form-item>
          <a-form-item label="権限">
            <a-select
              mode="multiple"
              placeholder="権限"
              :value="selectedRoles"
              style="width: 200px"
              @change="handleChange"
            >
              <a-select-option v-for="item in filteredOptions" :key="item.value" :value="item.label">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <hr style="margin: 20px 0">
      </div>
      <a-form-model-item label="所属ユーザー" :labelCol="{span: 3}" :wrapperCol="{span: 21}">
          <a-checkbox-group
            v-model="activeUsers"
          >
            <a-col :xs="12" :sm="6" v-for="item in filteredUser" :key="item.value">
              <a-checkbox :key="item.value" :value="item.value">{{ item.label }}</a-checkbox>
            </a-col>
          </a-checkbox-group>
      </a-form-model-item>
      <div class="text-center">
        <a-button type="primary" @click="onSubmit" :loading="btnLoading" :class="$style.filledBtn">
          更新
        </a-button>
        <router-link :to="backPath">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </div>
    </a-form-model>
  </div>
</template>

<script>

import Vue from 'vue'
import { enumData } from '@/services/enum'

const OPTIONS = enumData.userRole.filter(r => r.value !== 0)

export default {
  data() {
    return {
      allUsers: [],
      activeUsers: [],
      selectedRoles: [],
      searchText: '',
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      btnLoading: false,
    }
  },
  props: [
    'propAllUsers',
    'propActiveUsers',
    'backPath',
    'putPath',
  ],
  computed: {
    filteredOptions() {
      return OPTIONS.filter(o => !this.selectedRoles.includes(o))
    },
    autocompleteData() {
      return this.allUsers.map((item) => { return item.label })
    },
    filteredUser() {
      let filteredData = this.allUsers
      if (this.searchText) {
        filteredData = filteredData.map((u) => {
          if (u.label.includes(this.searchText)) {
            u.disabled = false
          } else {
            u.disabled = true
          }
          return u
        })
      } else {
        filteredData = filteredData.map((u) => {
          u.disabled = false
          return u
        })
      }
      if (this.selectedRoles.length) {
        filteredData = filteredData.map((u) => {
          if (u.disabled === true) return u
          if (this.selectedRolesValue.includes(u.role)) {
            u.disabled = false
          } else {
            u.disabled = true
          }
          return u
        })
      }
      return filteredData.sort((a, b) => a.disabled ? 1 : -1)
    },
    selectedRolesValue() {
      return this.selectedRoles.map(v => enumData.userRole.find(target => target.label === v).value)
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    searchAction(text) { this.searchText = text },
    onSubmit() {
      this.btnLoading = true
      const _this = this
      const send = Vue.prototype.$api.send('put', _this.putPath, { users: this.activeUsers })
      send.then(response => {
        _this.$notification['success']({
          message: '更新しました。',
        })
        _this.$router.push(_this.backPath)
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 更新に失敗しました。',
          })
          this.btnLoading = false
        })
    },
    handleChange(selectedRoles) {
      this.selectedRoles = selectedRoles
    },
  },
  mounted() {
    this.allUsers = this.propAllUsers
    this.activeUsers = this.propActiveUsers
  },
}
</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
h2 {
  font: normal normal bold 14px/33px $v2-font-family;
  letter-spacing: 0px;
}
</style>
